<template>
  <section class="invoice-preview-wrapper">
    <b-row>
      <!-- Col: Left (Invoice Container) -->
      <b-col>
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrappern mb-1">
                  <b-img :src="appLogoImage" alt="logo" width="150px" />
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Buchung Nr.
                  <span v-if="booking.invoiceNumber" class="invoice-number">
                    {{ booking.invoiceNumber }}
                  </span>
                  <span v-else class="invoice-number"> ###### </span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Datum:
                    <span v-if="booking.order.orderDate" class="invoice-date">
                      {{ getFormattedDate(booking.order.orderDate) }}
                    </span>
                    <span v-else class="invoice-date"> ###### </span>
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing" />

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col>
                <h6 class="mb-2">Kunde</h6>
                <h6 class="mb-25">
                  {{ booking.customer.firstName }}
                  {{ booking.customer.lastName }}
                </h6>
                <p class="card-text mb-25">
                  {{ booking.customer.organizationName }}
                </p>
                <p class="card-text mb-25">
                  {{ booking.customer.street }}
                  {{ booking.customer.streetNumber }}
                </p>
                <p class="card-text mb-25">
                  {{ booking.customer.zipCode }}
                  {{ booking.customer.city }}
                </p>
                <p class="card-text mb-25">
                  {{ booking.customer.country }}
                </p>
                <p class="card-text mb-0">
                  {{ booking.customer.email }}
                </p>
              </b-col>

              <!-- Col: Invoice To -->
              <b-col>
                <h6 class="mb-2">Rechnungsadresse</h6>
                <h6 class="mb-25">
                  {{ booking.customer.organizationName }}
                </h6>
                <p class="card-text mb-25">
                  {{ booking.customer.billingStreet }}
                  {{ booking.customer.billingStreetNumber }}
                </p>
                <p class="card-text mb-25">
                  {{ booking.customer.billingZipCode }}
                  {{ booking.customer.billingCity }}
                </p>
                <p class="card-text mb-25">
                  {{ booking.customer.billingCountry }}
                </p>
              </b-col>

              <!-- Col: Bank Details -->
              <b-col cols="4">
                <div>
                  <h6 class="mb-2">Bankinformationen</h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Bank:
                          <span> {{ booking.customer.bankName }} </span>
                        </td>
                        <td>
                          <span class="font-weight-bold" />
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          IBAN:
                          <span> {{ booking.customer.bankIBAN }} </span>
                        </td>
                        <td />
                      </tr>
                      <tr>
                        <td class="pr-1">
                          BIC:
                          <span> {{ booking.customer.bankBIC }} </span>
                        </td>
                        <td />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="booking.selectedStorages"
            :fields="fields"
          >
            <template #cell(rentStart)="data">
              {{ getFormattedDate(data.item.rentStart) }}
            </template>
            <template #cell(rentEnd)="data">
              {{
                data.item.rentEnd
                  ? getFormattedDate(data.item.rentEnd)
                  : 'Offen'
              }}
            </template>

            <template #cell(price)="data"> {{ data.item.price }} € </template>

            <template #cell(discount)="data">
              {{ data.item.discount }} €
            </template>
          </b-table-lite>

          <b-row class="match-height">
            <b-col>
              <b-card>
                <b-form-group label="Details" label-for="Details">
                  <b-form-textarea
                    id="orderDetails"
                    v-model="booking.order.orderDetails"
                    rows="3"
                    autofocus
                    trim
                    placeholder="Buchungsdetails hinzufügen..."
                  />
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="!parentRoute" class="match-height">
            <b-col xl="12">
              <b-card>
                <b-form-group
                  label="Kundenunterschrift"
                  label-for="Unterschrift"
                >
                  <img
                    v-if="booking.invoiceNumber"
                    :src="booking.SignatureImage"
                  />
                  <VueSignaturePad
                    v-else
                    id="signature"
                    ref="signaturePad"
                    width="100%"
                    height="200px"
                    :options="{ onBegin, onEnd }"
                  />
                </b-form-group>
              </b-card>
            </b-col>
            <b-col />
          </b-row>
          <b-row v-if="!parentRoute" class="match-height">
            <b-col xl="12">
              <b-card>
                <b-form-group
                  label="Vermieterunterschrift"
                  label-for="Unterschrift"
                >
                  <img
                    v-if="booking.invoiceNumber"
                    :src="booking.landlordSignatureImage"
                  />
                  <VueSignaturePad
                    v-else
                    id="signature"
                    ref="signaturePadLandlord"
                    width="100%"
                    height="200px"
                    :options="{ onBegin, onEnd }"
                  />
                </b-form-group>
              </b-card>
            </b-col>
            <b-col />
          </b-row>

          <hr class="invoice-spacing" />

          <b-row class="match-height">
            <b-col>
              <!-- Note -->
              <b-card-body>
                <div>
                  www.der-lagermeister.de | Rudolf-Diesel Str. 29-31 | D-48157
                  Münster | (+49) 0251 136226 | UST-ID: DE164242518
                </div>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BFormTextarea,
  BFormGroup,
  BImg,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { VueSignaturePad } from 'vue-signature-pad'
import { $themeConfig } from '@themeConfig'
import helper from '@/helper/helper'

export default {
  directives: {
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormTextarea,
    Logo,
    BFormGroup,
    BImg,
    VueSignaturePad,
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    const { getFormattedDate } = helper()

    const printInvoice = () => {
      window.print()
    }

    return {
      printInvoice,
      appName,
      appLogoImage,
      getFormattedDate,
    }
  },
  props: ['booking', 'parentRoute'],

  data() {
    return {
      fields: [
        {
          key: 'storageIdent',
          label: 'Lagernummer',
        },
        {
          key: 'name',
          label: 'Lagername',
        },
        {
          key: 'rentStart',
          label: 'Mietbeginn',
        },
        {
          key: 'rentEnd',
          label: 'Mietende',
        },
        {
          key: 'price',
          label: 'Monatspreis',
        },
        {
          key: 'discount',
          label: 'Rabatt',
        },
      ],
      isLandlordSignature: false,
    }
  },

  methods: {
    onBegin() {
      console.log('onBegin')

      this.$refs.signaturePad.resizeCanvas(
        this.$refs.signaturePad.$refs.signaturePadCanvas
      )

      this.$refs.signaturePadLandlord.resizeCanvas(
        this.$refs.signaturePadLandlord.$refs.signaturePadCanvas
      )
    },

    onEnd() {
      console.log('onEnd')
      this.saveSignature()
    },

    clearSignature() {
      this.$refs.signaturePad.clearSignature()
      this.$refs.signaturePadLandlord.clearSignature()
    },

    saveSignature() {
      if (!this.$refs.signaturePadLandlord.isEmpty()) {
        console.log('landlord')
        // eslint-disable-next-line operator-linebreak
        const { isEmpty, data } =
          this.$refs.signaturePadLandlord.saveSignature()
        // alert('Open DevTools see the save data.')
        if (!isEmpty) {
          console.log(data)
          // this.booking.order.landlordSignature = this.DataURIToBlob(data)
          this.booking.landlordSignatureImage = data
        }
      }

      if (!this.$refs.signaturePad.isEmpty()) {
        console.log('customer')
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
        // alert('Open DevTools see the save data.')
        if (!isEmpty) {
          console.log(data)
          this.booking.order.customerSignature = this.DataURIToBlob(data)
          this.booking.customerSignatureImage = data
        }
      }
    },
    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(',')
      // eslint-disable-next-line operator-linebreak
      const byteString =
        splitDataURI[0].indexOf('base64') >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      return new Blob([ia], { type: mimeString })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
